import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { PermissionService } from "src/app/auth/permission.service";
import { PERSISTANT_ITEM } from "src/app/_models/global.data.model";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { CustomersService } from "src/app/_services/customers.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { TranslateService } from "@ngx-translate/core";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";
import { N } from "@fullcalendar/core/internal-common";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-view-contact",
  templateUrl: "./view-contact.component.html",
  styleUrls: ["./view-contact.component.scss"],
})
export class ViewContactComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  public items: any[] = [];

  contactId: any;
  data: any;
  create!: boolean;
  update!: boolean;
  remove!: boolean;
  addContact: any;
  addonFeature: any;
  dateFormat: any;
  timezone!: string;

  refresher!: Subscription;

  private currentUrl = "";
  mainLabel :any= 'Sites'
  translate: any;
  comingFrom: string;
  constructor(
    private perm: PermissionService,
    private customerService: CustomersService,
    public auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private deleteRecordService: DeleteRecordService,
    private util: UtilServiceService,
    private languageTranslateService: LanguageTranslateService,
    private spinner :NgxSpinnerService
  ) {
    super();
   this.spinner.show();
  }

  ngOnInit(): void {
    this.getLanguageData();
    
  }

  /******************************************** Init ******************************************/
  private init() {
    this.currentUrl = document.URL;

    this.dateFormat = localStorage.getItem("date_format");
    this.timezone = this.auth.getUserTimezone();
    this.addonFeature = this.auth.getAddonFeatureLists();

    if (this.currentUrl && this.currentUrl.includes("sites") || this.currentUrl.includes("site")) {
      this.create = this.perm.capable("site", "create");
      this.update = this.perm.capable("site", "update");
      this.remove = this.perm.capable("site", "remove");
    } else if (this.currentUrl && this.currentUrl.includes("projects") || this.currentUrl.includes("project")) {
      this.create = this.perm.capable("project", "create");
      this.update = this.perm.capable("project", "update");
      this.remove = this.perm.capable("project", "remove");
    } else {
      this.create = this.perm.capable("customers", "create");
      this.update = this.perm.capable("customers", "update");
      this.remove = this.perm.capable("customers", "remove");
    }

    this.route.queryParams.subscribe((params) => {
      this.contactId = params["id"];
      this.getContactDetails();
    });

    this.items = [
      {
        id:'btn_ContaDetDelete',
        label: this.translate?.general.buttons.delete.singular,
        command: (e: any) => {
          this.menuAction("delete");
        },
        visible: this.remove,
      },
    ];
  }

  /******************************************** Get Contact Details ******************************************/
  private getContactDetails() {
    this.customerService.getUserId(this.contactId).subscribe((res: any) => {
      // console.log("RESP", res);
      this.addContact = res.body;
this.spinner.hide();
      console.log(this.addContact);

      this.loadBreadCrumbs();
    });
  }

  /******************************************** Load Breadcrumbs ******************************************/
  private loadBreadCrumbs() {
    this.addonFeature = this.auth.getAddonFeatureLists();
    // if(this.addonFeature.addonMS == 'MACHINE_SPECIALIST'){
    //   this.mainLabel = 'Estate'
    // }
    if (this.currentUrl && this.currentUrl.includes("sites") || this.currentUrl.includes('site')) {
      const data = this.util.getPersistantItem(PERSISTANT_ITEM.CONTACTS);
      console.log(data,"prdata")
      this.comingFrom = "projects";
        this.breadcrumbService.setItems([
          { label: this.translate?.site.label.plural, routerLink: ["/dashboard/sites"], meta: {navigation: {root: true}} },
          {
            label: this.translate?.general.buttons.view.singular+" "+this.translate?.contacts.label.plural,
            command: (e) => {
              this.router.navigate(["dashboard/sites/view/" + data.id], {
                queryParams: { id: data.id },
              });
            },
          },
          { label: this.translate?.general.buttons.view.singular+" "+ this.translate?.contacts.label.singular , meta: {navigation: {include: true}} },
          
        ]);
    } else if (this.currentUrl && this.currentUrl.includes("projects") || this.currentUrl.includes('project')) {
      const data = this.util.getPersistantItem(PERSISTANT_ITEM.CONTACTS);
      this.comingFrom = "projects";
        this.breadcrumbService.setItems([
          { label: this.translate?.project.label.plural, routerLink: ["/dashboard/projects"], meta: {navigation: {root: true}} },
          {
            label:this.translate?.general.buttons.view.singular+" "+this.translate?.contact.label.plural,
            command: (e) => {
              this.router.navigate(["dashboard/projects/view/" + data.id], {
                queryParams: { id: data.id },
              });
            },
          },
          { label: this.translate?.general.buttons.view.singular+" "+ this.translate?.contacts.label.singular , meta: {navigation: {include: true}} },
          
        ]);
    } else {
      this.comingFrom = "customer";
      // if(this.addonFeature.addonMS == 'MACHINE_SPECIALIST'){
      //   this.mainLabel = 'SOU'
      // }
      // this.breadcrumbService.setItems([
      //   { label: this.mainLabel, routerLink: ["/dashboard/customers/all"], meta: {navigation: {root: true}} },
      //   {
      //     label: "View Customer",
      //     command: (e) => {
      //       this.router.navigate(
      //         [
      //           "dashboard/customers/view/" +
      //             this.addContact.contactLite.customer.id,
      //         ],
      //         { queryParams: { id: this.addContact.contactLite.customer.id } }
      //       );
      //     },
      //   },
      //   { label: "View Contact", meta: {navigation: {include: true}} },
      // ]);  
        this.breadcrumbService.setItems([
          { label: this.translate?.customer.label.plural, routerLink: ["/dashboard/customers/all"], meta: {navigation: {root: true}} },
          { label: this.translate?.general.buttons.view.singular+" " + this.translate?.contacts.label.singular , meta: {navigation: {include: true}} },
          
        ]);
    }
  }

  /********************************** Click Cell Event *****************************/
  public editContact(e: any) {
    console.log(this.currentUrl, this.currentUrl.includes("sites") );
    if (e === "edit") {
      if (this.currentUrl && this.currentUrl.includes("sites") || this.currentUrl.includes("site")) {
        const data = this.util.getPersistantItem(PERSISTANT_ITEM.CONTACTS);
        if(data)
        {
          this.util.setPersistantItem(PERSISTANT_ITEM.CONTACTS, {
            accessFrom: "sites",
            id: data.id,
            label: data.label,
          });
        }


        this.router.navigate(
          [
            "/dashboard/sites/contacts/update/" +
              this.addContact.contactLite.id,
          ],
          {
            queryParams: { id: this.addContact.contactLite.id },
          }
        );
      } else if (this.currentUrl && this.currentUrl.includes("projects") || this.currentUrl.includes("project")) {
        const data = this.util.getPersistantItem(PERSISTANT_ITEM.CONTACTS);

        this.util.setPersistantItem(PERSISTANT_ITEM.CONTACTS, {
          accessFrom: "projects",
          id: data.id,
          label: data.label,
        });

        this.router.navigate(
          [
            "/dashboard/projects/contacts/update/" +
              this.addContact.contactLite.id,
          ],
          {
            queryParams: { id: this.addContact.contactLite.id },
          }
        );
      } else {
        this.router.navigate(["dashboard/customers/contact/Update"], {
          queryParams: { cid: this.addContact.contactLite.id },
        });
      }
    } else if (e === "delete") {
      this.addContact.contactLite.deleteHeader = this.translate?.contacts.label.singular,
      this.addContact.contactLite.deleteType = "Contact";
      this.addContact.contactLite.message =
      this.translate?.general.messages.confirmDelete+" " +
        this.addContact.contactLite.fullName +
        "?";

      this.deleteRecordService
        .getItems(this.addContact.contactLite)
        .subscribe((resp) => {
          if (resp.status === 200) {
            if (this.currentUrl && this.currentUrl.includes("sites") || this.currentUrl.includes("site")) {
              const data = this.util.getPersistantItem(
                PERSISTANT_ITEM.CONTACTS
              );

              this.router.navigate(["dashboard/sites/view/" + data.id], {
                queryParams: { id: data.id },
              });
            } else if (
              this.currentUrl &&
              this.currentUrl.includes("projects") || this.currentUrl.includes("project")
            ) {
              const data = this.util.getPersistantItem(
                PERSISTANT_ITEM.CONTACTS
              );

              this.router.navigate(["dashboard/projects/view/" + data.id], {
                queryParams: { id: data.id },
              });
            } else {
              this.router.navigate(
                [
                  "dashboard/customers/view/" +
                    this.addContact.contactLite.customer.id,
                ],
                { queryParams: { id: this.addContact.contactLite.customer.id } }
              );
            }
          }
        });
    }
  }

  /******************************************** Menu Action ******************************************/
  public menuAction(action: string) {
    if (action === "delete") {
      this.editContact("delete");
    }
  }



  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
        this.init();
      }
    }))
    console.log("translate",this.translate)
  }
  


   push(obs:any) {
    super.push(obs);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }


}
