import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { IContextMenuItem } from "src/app/shared/dataModels/general";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { PermissionService } from "src/app/auth/permission.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { NgxSpinnerService } from "ngx-spinner";
import { AssetService } from "src/app/_services/asset.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { DynamicLabelsService } from "src/app/_services/dynamic-labels.service";
import { LanguageTranslateService } from "src/app/_services/language-translate.service";

@Component({
  selector: "app-ticket-details",
  templateUrl: "./ticket-details.component.html",
})
export class TicketDetailsComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  @Input() ticketsId: any;

  @Input() ticketDetails: any;
  addonFeatureINVENTORY: any;

  diffDays!: number;
  expiredWarranty: boolean = false;
  atRiskDateTime!: string;
  dateFormat: any;
  timezone: any;

  // SLA
  slaSteps: any;
  noSlaStep: boolean = false;
  slaData: any;

  permissions = {
    siteShow: false,
    projectShow: false,
  };

  updateAssets!: boolean;
  dialogStatusData = {
    module: "",
    recordNumber: "",
    actionStatus: "",
    actionDescription: false,
    multipleDataAccept: "",
    headerTitle: "",
    oldStatus: "",
    newStatus: ""
  };
  showStatusDialog!: boolean;
  addonFeature: any;
  assetsStatusId: any;
  assetsSummary: any;
  translate: any;

  constructor(
    private util: UtilServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private perm: PermissionService,
    private spinner: NgxSpinnerService,
    private assetService: AssetService,
    private errorUtil: ErrorUtil,    
    private languageTranslateService: LanguageTranslateService,
  ) {
    super();
    this.getLanguageData()
  }
  ngOnInit(): void {
    this.init();
  }

  /******************************************** init ******************************************/
  private init() {
    this.processPermission();

    this.dateFormat = localStorage.getItem("date_format");

    if (!this.ticketsId) {
      this.ticketsId = this.route.snapshot.paramMap.get("id");
    }

    this.timezone = this.auth.getUserTimezone();
    this.addonFeature = this.auth.getAddonFeatureLists();
    this.addonFeatureINVENTORY =
      this.auth.getAddonFeatureLists().addonINVENTORY;

    this.diffDays = 0;

    this.loadData();
  }

  /******************************************** load data ******************************************/
  private loadData() {
    let today: any = new Date();

    if (this.ticketDetails) {
      if (this.ticketDetails.assets != null) {
        if (
          this.ticketDetails.assets.warrantyStartDate != null &&
          this.ticketDetails.assets.warrantyEndDate
        ) {
          let warrantyStartDate: any = new Date(
            this.ticketDetails.assets.warrantyStartDate
          );
          let warrantyEndDate: any = new Date(
            this.ticketDetails.assets.warrantyEndDate
          );

          if (warrantyEndDate < today) {
            this.expiredWarranty = true;
          } else {
            this.expiredWarranty = false;
          }
        }
      }
    }
  }

  /******************************************** right click item ******************************************/
  public rightClickItem($event: any, what: string) {
    const contextMenuItems: IContextMenuItem[] = [];

    if (what === "customer") {
      this.util.resetTabView();

      contextMenuItems.push({
        name: "Open In New Tab",
        icon: "pi pi-external-link",
        command: () => {
          const id = this.ticketDetails.customer.id;
          const path = `dashboard/customers/view/${id}?id=${id}`;
          this.util.openItemToNewTab(path);
        },
      });
    }

    if (what === "asset") {
      contextMenuItems.push({
        name: "Open In New Tab",
        icon: "pi pi-external-link",
        command: () => {
          const id = this.ticketDetails.assets.id;
          const path = `dashboard/assets/view/${id}?id=${id}`;
          this.util.resetTabView();

          this.util.openItemToNewTab(path);
        },
      });
    }

    if (what === "jobOrder") {
      contextMenuItems.push({
        name: "Open In New Tab",
        icon: "pi pi-external-link",
        command: () => {
          const id = this.ticketDetails.jobOrders[0].id;
          const path = `dashboard/jobOrders/view/${id}?jobOrderId=${id}`;
          this.util.resetTabView();

          this.util.openItemToNewTab(path);
        },
      });
    }

    if (what === "firstAgent") {
      contextMenuItems.push({
        name: "Open In New Tab",
        icon: "pi pi-external-link",
        command: () => {
          const id = this.ticketDetails.firstJobOrder.orderOwner.id;
          const path = `dashboard/setup/allUser/view${id}?id=${id}&edit:false`;
          this.util.resetTabView();

          this.util.openItemToNewTab(path);
        },
      });
    }

    if (what === "createdUser") {
      contextMenuItems.push({
        name: "Open In New Tab",
        icon: "pi pi-external-link",
        command: () => {
          const id = this.ticketDetails.createdByUser.id;
          const path = `/dashboard/setup/allUser/view${id}?id=${id}&edit:false`;
          this.util.resetTabView();

          this.util.openItemToNewTab(path);
        },
      });
    }

    if (what === "ticketOwner") {
      contextMenuItems.push({
        name: "Open In New Tab",
        icon: "pi pi-external-link",
        command: () => {
          const id = this.ticketDetails.ticketOwner.id;
          const path = `/dashboard/setup/allUser/view?id=${id}&edit:false`;
          this.util.resetTabView();

          this.util.openItemToNewTab(path);
        },
      });
    }

    if (what === "lastModifiedUser") {
      contextMenuItems.push({
        name: "Open In New Tab",
        icon: "fa fa-arrows-h",
        command: () => {
          const id = this.ticketDetails.lastModifiedByUser.id;
          const path = `/dashboard/setup/allUser/view?id=${id}&edit:false`;
          this.util.resetTabView();

          this.util.openItemToNewTab(path);
        },
      });
    }

    this.util.rightClick("body", $event, contextMenuItems);
  }

  /******************************************** view page ******************************************/
  public viewPage(data: any, page: string) {
    this.util.resetTabView();

    if (page === "jobOrderNumber")
      this.router.navigate(
        ["dashboard/jobOrders/view/" + data.firstJobOrder.id],
        {
          queryParams: { jobOrderId: data.firstJobOrder.id },
        }
      );
    else if (page === "customer")
      this.router.navigate(["dashboard/customers/view/" + data.customer.id], {
        queryParams: { id: data.customer.id },
      });
    else if (page === "assets") {
      localStorage.setItem("activeIndexAssetView", "0");
      this.router.navigate(["dashboard/assets/view/" + data.assets.id], {
        queryParams: { id: data.assets.id },
      });
    } else if (page === "contact") {
      this.router.navigate(
        ["dashboard/customers/contact/view/" + data.contact.id],
        { queryParams: { id: data.contact.id } }
      );
    }
  }

  /******************************************** view group ******************************************/
  public viewGroup(id: any) {
    this.util.resetTabView();

    this.router.navigate(["/dashboard/setup/groups/view/"+id], {
      queryParams: { id: id },
    });
  }

  /******************************************** view ticket ******************************************/
  public viewTicket(id: any, edit: boolean) {
    this.util.resetTabView();

    this.router.navigate(["/dashboard/tickets/view/" + id], {
      queryParams: { id: id },
    });
  }

  /******************************************** view user ******************************************/
  public viewUser(id: any, edit: boolean) {
    this.router.navigate(["/dashboard/setup/allUser/view"], {
      queryParams: { id: id, edit: false },
    });
  }

  /******************************************** View ******************************************/
  public view(what: string, data: any) {
    this.util.resetTabView();

    if (what === "site") {
      const id = data.id;
      this.router.navigate(["/dashboard/sites/view/" + id], {
        queryParams: { id: id },
      });
    }

    if (what === "project") {
      const id = data.id;
      this.router.navigate(["/dashboard/projects/view/" + id], {
        queryParams: { id: id },
      });
    }
  }

  /******************************************** Process Permission ******************************************/
  private processPermission() {
    this.permissions.siteShow = this.perm.capable("site", "read");
    this.permissions.projectShow = this.perm.capable("project", "read");
  }

  getAssetsSummary()
  {
    this.spinner.show()
    this.push(
    this.assetService.getAssetsSummeryById(this.ticketDetails?.assets?.id).subscribe((res: any) => {
      // this.spinner.hide();
      this.assetsSummary = res.body;
      this.assetsStatusId = this.assetsSummary.assetsStatus.id;
      this.spinner.hide()
      console.log("summary",this.assetsSummary)
    })
    )
  }


  openRightNav(){

    this.getAssetsSummary()

    const a = document.querySelector('.right-pane-fr-summ');
      a!.classList.remove('slide-right');
      a!.classList.add('slide-left-two-summ');
  }
  closeRightNav(){
    const a = document.querySelector('.right-pane-fr-summ');

    if(a!.classList.contains('slide-left-two-summ')) {      
      a!.classList.remove('slide-left-two-summ');
      a!.classList.add('slide-right');
    }
    else{
      a!.classList.remove('slide-right');
      a!.classList.add('slide-left-two-summ');
    }
   
  }

  onAcceptAssetStatus(data: any)
  {
    this.showStatusDialog = true;
    this.dialogStatusData.module = this.translate?.asset.label.singular
    this.dialogStatusData.recordNumber = data.name
    this.dialogStatusData.actionStatus = "Change Status"; 
    this.dialogStatusData.actionDescription = false
    this.dialogStatusData.multipleDataAccept = "ASSET STATUS";
    this.dialogStatusData.headerTitle = this.translate?.general.status.messages.changeStatus
    this.dialogStatusData.oldStatus = data.oldStatus;
    this.dialogStatusData.newStatus = data.newStatus;
  }
  onClickDialogYes(event: any)
  {
    this.spinner.show();
    if(event)
    {
      this.assetService.updateAssetsStatus(this.assetsStatusId,this.assetsSummary.id).subscribe((res: any) =>{
        if(res.message == 'success')
        {
          this.getAssetsSummary();
          this.showStatusDialog = false;
          this.errorUtil.setErrorMessage(
            200,
            this.translate?.general.messages.updatedSuccessfully,
            null,
            "success",
            2000
          );
          this.spinner.hide()
  
        }
        else{
          this.errorUtil.setErrorMessage(
            400,
            this.translate?.general.messages.updateNotSuccesful,
            null,
            "error",
            2000
          );
          }

      })
    }
  }

  visibleOut(e: any) {
    this.showStatusDialog = e;
    this.getAssetsSummary();
    console.log(e);
  }

  onClickBackground()
  {
    const a = document.querySelector('.right-pane-fr-summ');

    if(a!.classList.contains('slide-left-two-summ')) {      
      a!.classList.remove('slide-left-two-summ');
      a!.classList.add('slide-right');
    }
  }

  onChageAssetStatus(e: any)
  {
    this.assetsStatusId = e
  }

  push(obs: any) {
    super.push(obs);
  }

  getLanguageData() {
    this.push(this.languageTranslateService.wordSrc$.subscribe(data => { 
      if(Object.keys(data).length != 0){
        this.translate = data
      }
    }))
    console.log("translate",this.translate)
    //this.mainLabel = this.translate?.site.label.plural
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
