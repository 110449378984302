import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from 'src/app/_services/admin/users.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { response } from 'express';
import { marked } from 'marked';
@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit {
  userMessage: string | undefined;
  chatInput!: HTMLTextAreaElement;
  sendChatBtn!: HTMLSpanElement;
  chatbox!: HTMLDivElement;
  showBotElement!: HTMLDivElement;
  chatToggle!: HTMLButtonElement;
  @ViewChild('chatbox') private chatBoxElement!: ElementRef;

  apidata: SafeHtml | null = null; // Simulate new message data

  constructor(
    private spinner: NgxSpinnerService,
    private usersService: UsersService,
    private errorUtil: ErrorUtil,
    private auth: AuthenticationService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
   this.loadChatbot()
   
  }

  createChatLi(message: string, className: string): HTMLElement {
    /* Adding outgoing bubble when user sends messages */
    const chatLi = document.createElement("li");
    chatLi.classList.add("chat", className);

    const messageElement = document.createElement("p"); // Create the paragraph for the message
    messageElement.innerText = message;

    let chatContent = className === "outgoing"
      ? `<p>${message}</p>`
      : `<span>${message}</span>`;

      if (className === "incoming") {
        const iconElement = document.createElement("i"); // Create icon element if it's an incoming message
        iconElement.classList.add("fa-solid", "fa-message-bot"); // Add robot icon
        chatLi.appendChild(iconElement); // Append the icon to the list item
      }

    // chatLi.innerHTML = chatContent;
    chatLi.appendChild(messageElement)
    return chatLi;
    
  }

  handleChat(): void {
    if (!this.chatInput) return;

    this.userMessage = this.chatInput.value.trim();
    if (!this.userMessage) return;

    /* Append message to chatbox to outgoing/user*/
    this.chatbox.appendChild(this.createChatLi(this.userMessage, "outgoing"));

    /* Append message to chatbox for incoming */
    setTimeout(()=>{
      this.chatbox.appendChild(this.createChatLi("Hello Hari! You sound worry.", "incoming"));
      this.scrollToBottom(); // function for chatbox scrolls to the bottom
    }, 300)
  }
    // Function to scroll to the bottom and add height
    scrollToBottom(): void {
      // chatbox scrolls to the bottom
      this.chatbox.scrollTop = this.chatbox.scrollHeight;

      // adding height to chatbox
      this.chatbox.style.height = '375px';
    }
  toggleBotVisibility(): void{
    // toggle chatbot
    this.showBotElement.classList.toggle('show-bot');

  }
  /****************************************** Get Connect With AI *****************************/
  getConnectWithAI(){
    
    
      this.spinner.show();
     this.usersService.getConnectWithAI(this.auth.getUserId(),'2024-09-01T06:19:09Z','2024-09-27T08:59:29Z').subscribe((response:any) => {
        //console.log(response.body.data,"========")
      // var  response ={
      //     "status": true,
      //     "data": "Hey Custella Admin! It's your helpful virtual assistant here!\n\nHere's the rundown of your job allocations for the day:\n\n| **Location**                                                                                     | **Job Order Type**        | **Count** |\n|--------------------------------------------------------------------------------------------------|----------------------------|-----------|\n| No. 7, Jalan Penyajak U1/45A, Seksyen U1 Glenmarie, Shah Alam, Selangor, 40150, Malaysia      | Site Inspection            | 1         |\n| Klcc Twin Towers, 1, Jalan Ampang, Kuala Lumpur City Centre, Kuala Lumpur, 50450, Malaysia    | Machine Installation       | 1         |\n| **Total Jobs**                                                                                  |                            | **2**     |\n\nIt looks like you have a couple of exciting tasks lined up today! Remember to pace yourself and take breaks when you can. You've got this! 🌟"
      // }
      var rawHtml:any = marked.parse(response.body.data);
        this.apidata = this.sanitizer.bypassSecurityTrustHtml( rawHtml);
        setTimeout(() => this.scrollToBottom(), 0);
          this.spinner.hide();
      }, (error) => {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(error);this.errorUtil.setErrorMessage(
          error.error.status,
          error.error.title,
          null,
          "error",
          2000
        );
      });
    
}

  // Method to scroll to the bottom of the chatbox
  scrollToBottom1(): void {
    try {
      this.chatBoxElement.nativeElement.scrollTop = this.chatBoxElement.nativeElement.scrollHeight;
    } catch (err) {
      console.log('Error scrolling to bottom:', err);
    }
  }
loadChatbot(): void {
  this.chatInput = document.querySelector<HTMLTextAreaElement>(".chat-input textarea")!;
  this.sendChatBtn = document.querySelector<HTMLSpanElement>(".chat-input span")!;
  this.chatbox = document.querySelector<HTMLDivElement>(".chatbox")!;
  this.showBotElement = document.querySelector<HTMLDivElement>('.show-bot')!;
  this.chatToggle = document.querySelector<HTMLButtonElement>(".toggle-bot")!;

  this.sendChatBtn?.addEventListener("click", this.handleChat.bind(this));
  this.chatToggle.addEventListener('click', this.toggleBotVisibility.bind(this));
  this.getConnectWithAI();
}

}


