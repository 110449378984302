<div class="position-relative d-flex h-100-28" [hidden]=" noData == true">
    <div class="ag-grid-table-full h-100" [ngClass]="{ 'ag-grid-table': isFilterViewToggle }" [hidden]=" noData == true">
        <ag-grid-angular
            #agGrid
            class="ag-theme-balham h-100"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            [defaultColDef]="defaultColDef"
            [pagination]="false"
            [cacheOverflowSize]="2"
            [maxConcurrentDatasourceRequests]="-1"
            [suppressDragLeaveHidesColumns]="true"
            [allowContextMenuWithControlKey]="true"
            [frameworkComponents]="frameworkComponents"
            (cellClicked)="viewCell($event)"
            (gridReady)="onGridReady($event)"
            [getContextMenuItems]="getContextMenuItems"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        >
        </ag-grid-angular>

        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end" id="label_SitesTableRec">
                {{ totalRecords }}&nbsp;{{translate?.general.messages.records}}
            </p>
        </div>
    </div>

    <div *ngIf="noData" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
            <p class="text-6 my-1 font-weight-bold" >
                {{translate?.general.messages.noRecordsFound}}
            </p>
        </div>
    </div>

    <!-- Filter -->
    <div class="custella-form-container-content-pane layout-filter" [ngClass]="{ 'layout-filter-active': isFilterViewToggle }" *ngIf="isFilterViewToggle">
        <div class="custella-form-container-content-pane-title">
            <span class="text-14 font-medium" id="label_SitesFilter">{{ translate?.general.messages.filterBy }}</span>
            <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" tooltipPosition="left" (click)="toggleFilter()" id="btn_SitesFilterClose"></i>
        </div>

        <div class="custella-content">
            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_SitesType">{{translate?.site.fields.type.singular}}</span>
                <p-dropdown [options]="allSiteTypes" [filter]="true" [showClear]="filters.type ? true : false" [placeholder]="translate?.general.messages.none" optionLabel="label" optionValue="id" id="picklist_SitesTypeField" [(ngModel)]="filters.type" (onChange)="refresh()"></p-dropdown>
            </div>

            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_SitesServiceZone">{{ translate?.setup.serviceZone.label.singular }}</span>
                <p-dropdown [options]="allServiceZones" [filter]="true" id="picklist_SitesServiceZoneField" [showClear]="filters.service_zone ? true : false"  [placeholder]="translate?.general.messages.none" optionLabel="name" optionValue="id" [(ngModel)]="filters.service_zone" (onChange)="refresh()"></p-dropdown>
            </div>

            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_SitesCategoryType">{{ translate?.site.fields.category.singular }}</span>
                <p-dropdown [options]="allSiteCategorys" id="picklist_SitesCategoryTypeField" [filter]="true" [showClear]="filters.category_type ? true : false"  [placeholder]="translate?.general.messages.none" optionLabel="label" optionValue="id" [(ngModel)]="filters.category_type" (onChange)="refresh()"></p-dropdown>
            </div>

            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_SitesGeofence">{{ translate?.setup?.geofence?.label.singular }}</span>
                <p-dropdown [options]="allGeofences" [filter]="true" id="picklist_SitesGeofenceField" [showClear]="filters.geofence ? true : false"  [placeholder]="translate?.general.messages.none"optionLabel="geoFenceName" optionValue="id" [(ngModel)]="filters.geofence" (onChange)="refresh()"></p-dropdown>
            </div>

            <div class="custella-content-input col-12" *ngIf="!additionalData?.asInternal && addonFeature.addonPROJECT == 'PROJECT'">
                <span class="custella-content-input-title" id="label_SitesProject">{{ translate?.project.label.singular}}</span>
                <p-dropdown [options]="allProjects" [filter]="true" id="picklist_SitesProjectField"  [placeholder]="translate?.general.messages.none" optionLabel="name" optionValue="id" [(ngModel)]="filters.project" (onChange)="refresh()" [showClear]="filters.project ? true : false"></p-dropdown>
            </div>

            <div>
                <span class="clear" id="subtitle_SitesFilterClear" (click)="clearAll()">{{ translate?.general.buttons.clearAll.singular }}</span>
            </div>
        </div>
    </div>
</div>
