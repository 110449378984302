<div class="position-relative fw-normal col-12 d-flex h-100-28" [hidden]="noCustomers === null || noCustomers === true">
    <div class="table-space ag-grid-table-full h-100"[hidden]="noCustomers === null || noCustomers === true">
        <ag-grid-angular
        #agGrid
        style="height: 100%; width: 100%;"
        class="ag-theme-balham"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        [defaultColDef]="defaultColDef"
        [maxConcurrentDatasourceRequests]="-1"
        [suppressDragLeaveHidesColumns]="true"
        [allowContextMenuWithControlKey]="true"
        [getContextMenuItems]="getContextMenuItems"
        [frameworkComponents]="frameworkComponents"
        (gridReady)="onGridReady($event)"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        (cellClicked)="viewProduct($event)"
    ></ag-grid-angular>
        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end" id="label_ProdTableRec">
                {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
            </p>
        </div>
    </div>
</div>
