<div class="show-bot" [hidden]="!apidata">
    <button class="toggle-bot">
        <i class="fa-solid fa-message-bot"></i>
        <i class="fa-solid fa-chevron-down"></i>

    </button>

    <div class="chatbot">
        <header>
            <i class="fa-solid fa-sparkles"></i>
            <h3>FieldExpert Insights</h3>
        </header>
        <ul class="chatbox" #chatbox>
            <li class="chat incoming">
                <i class="fa-solid fa-message-bot"></i>
                <div class="chat-message" [innerHTML]="apidata"></div>
            </li>
            <!-- <li class="chat outgoing">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </li> -->
        </ul>

        <!-- <div class="chat-input">
            <textarea class="chat-textarea" pInputTextarea placeholder="Type a reply..."></textarea>
            <span>
                <i class="fa-solid fa-paper-plane-top"></i>
            </span>
        </div> -->
    </div>
</div>