import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { AppComponent } from 'src/app/app.component';
import { Router } from '@angular/router';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { WebSocketsService } from 'src/app/_services/web-sockets.service';
import { NotificationsService } from 'src/app/_services/notifications.service';
import { ApiService } from 'src/app/_services/api.service';
import { ProfilesService } from 'src/app/_services/admin/profiles.service';
import { UsersService } from 'src/app/_services/admin/users.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import * as moment from 'moment';
import { SettingService } from 'src/app/_services/admin/setting.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { PermissionService } from 'src/app/auth/permission.service';
import * as _ from 'lodash';
import { HeaderTitleService } from 'src/app/_services/header-title.service';

import { MenuService } from 'src/app/_services/menu.service';
import { PushNotificationOptions, PushNotificationService } from 'src/app/shared/ngx-push-notifications-master/push-notification.service';
import { IqcFqcService } from 'src/app/_services/iqc-fqc.service';
import { MessagesService } from 'src/app/_services/messages.service';
import { LanguageTranslateService } from 'src/app/_services/language-translate.service';
import { take } from 'rxjs/operators';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
declare var bootstrap: any;
@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss'],
  providers: [MessageService]
})
export class TopHeaderComponent extends SubscriptionUtil implements OnDestroy, AfterViewInit {

  subscription!: Subscription;

  items!: MenuItem[];
  tieredItems!: MenuItem[];
  /*=========== Login User Detail ============*/
  fullName!: string;
  userEmail!: string;
  username!: string;
  onlineStatus: boolean = false;
  currentLang="English";
  curentLangCode="en";
  flag: string = 'us'; // Default flag
/*=========== Enable Sales/ service / Fleet ============*/
  salesEnabled!: boolean;
  serviceEnabled!: boolean;
  fleetEnabled!: boolean;
  defaultTenantCategory: any;
  salePer:boolean = false;
  servicePer:boolean = false;
  fleetPer:boolean = false;
/*=========== Subscription Method to update data ============*/
  refreshStatus: Subscription;
  refresher: Subscription;
  refreshPhoto: Subscription;
  refreshOrgName: Subscription;
  refresher2: Subscription;
  refreshMessages : Subscription;
  refreshNotifications : Subscription;
  refreshOnlineStatus: Subscription;
  refreshLanguage: Subscription;
  refresherNotification: Subscription;
  notificationCount: any;

  stompClient: any;
  photoUrl: any;
  lockVariable: any;
  companyName!: string | null;
  trialDays!: number;
  setup!: boolean;
  createTask!: boolean;
  createCustomer!: boolean;
  messageCount: any;
  title!: string;
  notifications: any[]=[];

  visibleSidebarTask:boolean = false
  newTaskId: any;
  showCloseIcon:boolean=false
  showNotificationPanel: boolean = false;
  oldCount: any=0;
  words: any;
 
  @ViewChild('chooseLanguage') chooseLangEl: ElementRef;

  constructor(
    public app: AppComponent,
    private router: Router,
    private webSocket : WebSocketsService,
    private notificationService: NotificationsService,
    private apiService : ApiService,
    private profileService: ProfilesService,
    private userService : UsersService,
    private auth: AuthenticationService,
    private settingsService : SettingService,
    private util : UtilServiceService,
    private perm: PermissionService,
    private messageService : MessageService,
    private headerTitleService: HeaderTitleService,
    private menuService: MenuService,
    private iqcfqcService:IqcFqcService,
    private messagesService: MessagesService,
    private pushNotificationService: PushNotificationService,
    private languageTranslateService: LanguageTranslateService,
    private spinner : NgxSpinnerService
  ) {
    super();
    this.refresherNotification = this.notificationService.refreshList$.subscribe((item:any) => {
      console.log("refresherNotification")
      
      this.getNotificationCount();
    });

    this.refresher = this.util.reOpenTaskViewPage$.subscribe((item:any) => {
      //console.log(item)
      this.openTaskViewModal(item);
    });

    this.refresher2 = this.userService.refreshList$.subscribe((item:any) => {
      this.apiService.getProfile().pipe(take(1)).subscribe((res: any) => {
        var profile = {
          firstName : res.firstName,
          fullName : res.firstName + ' ' + res.lastName,
          email : res.email,
          tenantId : res.tenantId,
          tenantCategories:res.tenantCategories,
          createdDate:res.licenceVM.createdDate,
          renewalDate:res.licenceVM.validTill,
          trial:res.licenceVM.trial
        }
        
        ///this.useLanguage(this.getCurrentLanguage().code);
        
        localStorage.setItem('profile_info', btoa(JSON.stringify(profile)));
        localStorage.setItem('user_name',btoa(res.firstName));
        localStorage.setItem('user_full_name',btoa(profile.fullName));
        //console.log(profile,"profile")
        var profileInfo = JSON.parse(this.auth.getProfileInfo());
        this.fullName = profileInfo.fullName;
        this.username = this.auth.getUsername();
        this.userEmail = profileInfo.email;
      });
    });

    this.refreshStatus = this.userService.refreshStatus$.subscribe((item:any) => {
      if(item.indexOf('Online Status Updated') !== -1){
        this.loadUserInfo();
      }
    });

    this.refreshPhoto = this.profileService.refreshProfilePhoto$.subscribe(res => {
      this.loadUserInfo();
    });

    this.refreshOrgName = this.settingsService.refreshList$.subscribe((item:any) => {
      this.companyName = this.auth.getCompanyName();
    });

    this.refreshMessages = this.webSocket.refreshMessages$.subscribe((message) => {
      this.getUnreadMessages();
      if(message.body){
        var body = JSON.parse(message.body);
        this.generatePushNotification(body, 'message');
      }
    });

    this.refreshNotifications = this.webSocket.refreshNotifications$.subscribe((notification) => {
      this.getNotificationCount();
      var body = JSON.parse(notification.body);
     this.generatePushNotification(body, 'notification');
    });
   
    this.refreshOnlineStatus = webSocket.refreshOnlineStatus$.subscribe(user => {
      var body = JSON.parse(user.body);
      if(body.id == this.auth.getUserId()){
        this.onlineStatus = body.online;
        this.lockVariable = body.online;
      }
    });


      
      this.refreshNotifications = this.webSocket.refreshNotifications$.subscribe((notification) => {
        this.getNotifications(this.auth.getUserId());
      });

      this.refreshLanguage = this.settingsService.refreshTenant$.subscribe((tenant:any) => {
        console.log("emiteddd",tenant)
        //this.useLanguage(this.getCurrentLanguage().code);
      });
  }

  ngOnInit() {
    
    const isGranted = this.pushNotificationService.isPermissionGranted;
    if(!isGranted){
      this.pushNotificationService.requestPermission();
    }
    this.useLanguage(this.getCurrentLanguage().code); //it was calling twice
    //this.fleetConfigSetup();
   
  }

  push(obs:any) {
    super.push(obs);
  }

  ngAfterViewInit(): void {
    this.initializeFunstion()
    // initialize bootstap dropdown
    const dropdownElement = document.getElementById('dropdownMenu');
    new bootstrap.Dropdown(dropdownElement);

    // const accordionElement = document.getElementById('choose-lang');
    // new bootstrap.Collapse(accordionElement, { toggle: false });
  }

  /************************************* Get User Information **********************/
  loadUserInfo(){
    this.userService.getUserById(this.auth.getUserId()).subscribe((res: any) => {
      if(res){
        if(!localStorage.getItem('timezone')){
          this.storeUserProfile(res); 
        }// Store user profile details in local storage

        this.onlineStatus = res.online;
        this.lockVariable = res.online;
        if(res.superVisorId && res.superVisorName){
          localStorage.setItem('superVisor', JSON.stringify({id : res.superVisorId, name : res.superVisorName}));
        }
        var role = {id : res.roleId, name : res.roleName};
        localStorage.setItem('role',JSON.stringify(role));
        if(res.imageUrl && res.imageUrl !== ''){
          this.photoUrl = res.imageUrl;
        } 
        else {
          this.photoUrl = '../../../../assets/svg/Avatar.svg';
        }
      }
    });
  }
/************************************* Get Notification Count ****************************/
  getNotificationCount() {
    
    this.notificationService.getUnreadNotifications(this.auth.getUserId()).subscribe((res:any) => {
      this.notificationCount = res.length; //unread notifications
      console.log(this.notificationCount,"notificationCount","oldCount",this.oldCount)
      if(this.notificationCount < this.oldCount){
        this.showNotificationPanel = false;
      }

    });
  }
/************************************* Destroy all the  subscription variables **********************/
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.refresher.unsubscribe();
    this.refresher2.unsubscribe();
    this.refreshStatus.unsubscribe();
    this.refreshPhoto.unsubscribe();
    this.refreshOrgName.unsubscribe();
    this.refreshMessages.unsubscribe();
    this.refreshNotifications.unsubscribe();
    this.refreshOnlineStatus.unsubscribe();
    this.refreshLanguage.unsubscribe();
    this.refresherNotification.unsubscribe();
    super.ngOnDestroy()
    console.log("destroiesTH")
  }
  /************************************* Log Out Session and Socket **********************/
  doLogOut(){
    this.router.navigate(['login']);
    //this.languageTranslateService.completeSubjects()
    localStorage.clear();
    sessionStorage.clear();
    this.stompClient.disconnect();
  }
  /************************************* Initialize all the function  **********************/
  initializeFunstion() {
    
         var menuPermissions: any =  this.auth.menuPermissions()
         ////console.log(menuPermissions,"--- menuPermissions----",sessionStorage.getItem('menuType'),"=====",menuPermissions.defaultTenantCategory)
         setTimeout(() => {

         })
         let tempCategory = sessionStorage.getItem('menuType') ? sessionStorage.getItem('menuType') : menuPermissions.defaultTenantCategory;

         //console.log('TEMP ', sessionStorage.getItem('menuType'), tempCategory, menuPermissions)

         if (tempCategory === 'sales') {
          tempCategory = 'service';
         }

         this.defaultTenantCategory = tempCategory;
         ////console.log(this.defaultTenantCategory,"--- menuPermissions")
         this.salesEnabled = menuPermissions.sales;
         this.serviceEnabled = menuPermissions.service;
         this.fleetEnabled = menuPermissions.fleet;

        // console.log(this.salesEnabled);
       //  console.log(this.serviceEnabled);
        // console.log(this.fleetEnabled);


        //  set it as the first init
        this.headerTitleService.menuType(this.defaultTenantCategory);
   
   

    var trialDate = localStorage.getItem('trialEndDate');
    if(trialDate){
      var b = moment();
      var a = moment(trialDate);
      this.trialDays = a.diff(b, 'days')
    }

    this.companyName = this.auth.getCompanyName();
    this.createTask = this.perm.capable('tasks', 'create');
    this.createCustomer = this.perm.capable('customers', 'create');
   
    this.headerTitleService.title.subscribe(updatedTitle => {
      this.title = updatedTitle;
    });

    this.username = this.auth.getUsername();
    var profileInfo = JSON.parse(this.auth.getProfileInfo());
    this.fullName = profileInfo.fullName;
    this.userEmail = profileInfo.email;
    // 
    //  this.useLanguage(this.getCurrentLanguage().code); //it was calling twice

    this.salePer =  this.menuService.findMenuPermissionsSALES(profileInfo.tenantCategories);
    this.servicePer = this.menuService.findMenuPermissionsSERVICE(profileInfo.tenantCategories);
    this.fleetPer =  this.menuService.findMenuPermissionsFLEET(profileInfo.tenantCategories);

  

    this.getNotificationCount();
    this.getUnreadMessages();
    this.loadUserInfo();
    this.loadTenantInfo();
  //  this.fleetConfigSetup();
    this.setup = this.perm.capable('setup', 'read');
    

    this.initializeWebSocketConnection();

    // setInterval(() => {
    //   this.initializeWebSocketConnection();
    // }, 10000);
  //   $(".user-profile-menu .languages-button").click(function (event) {
  //     event.preventDefault();
  //     return false;
  // })
    

  }
  /******************************************** Store User Profile ******************************************/
  storeUserProfile(userProfileResponse: any) {
    var homeAddress = '';
    var officeAddress = '';

    // Construct home and office addresses
    for (let key in userProfileResponse) {
      if (key.startsWith('home') && userProfileResponse[key]) {
        homeAddress += userProfileResponse[key] + ', ';
      }
      if (key.startsWith('office') && userProfileResponse[key]) {
        officeAddress += userProfileResponse[key] + ', ';
      }
    }

    // Store the addresses in local storage
    if (homeAddress) {
      var homeAdd = { address: homeAddress, placeId: userProfileResponse.homePlaceId, lat: userProfileResponse.homeLatitude, lng: userProfileResponse.homeLongitude };
      localStorage.setItem('home_address', JSON.stringify(homeAdd));
    }
    if (officeAddress) {
      var officeAdd = { address: officeAddress, placeId: userProfileResponse.officePlaceId, lat: userProfileResponse.officeLatitude, lng: userProfileResponse.officeLongitude };
      localStorage.setItem('office_address', JSON.stringify(officeAdd));
    }

    localStorage.setItem('buffer', JSON.stringify({ start: userProfileResponse.startBuffer, end: userProfileResponse.endBuffer }));
    localStorage.setItem('timezone', userProfileResponse.timezone);
    localStorage.setItem('shiftWorking', userProfileResponse.shiftWorking);
  }


/************************************* On Change event : change defaultTenantCategory (service/ fleet/ sales) **********************/
  menuType(type : any){
    this.defaultTenantCategory = type;
    //localStorage.setItem('menuType',type);
    sessionStorage.setItem('menuType',type);
    if(type === 'sales' || type === 'service'){
      this.router.navigate(["/dashboard/home"]);
    }else{
      this.router.navigate(["/dashboard/homeFleet"]);
    }
    this.headerTitleService.menuType(type);
  }

  /************************************* Load Fleet Config To display some sections like DO, PH **********************/
  fleetConfigSetup(){
    this.settingsService.getFleetConfig().subscribe((res: any) => {
      var fleetConfig = {
        loadingDuration: res.body.loadingDuration,
        unloadingDuration: res.body.unloadingDuration,
        costPerTrip: res.body.costPerTrip,
        costPerKm: res.body.costPerKm,
        costPerMin: res.body.costPerMin,
        planCapacityCalculateBy: res.body.planCapacityCalculateBy,
        defaultOwner:res.body.defaultOwner,
        noOfStopsMax:res.body.noOfStopsMax,
        noOfVehicleMax:res.body.noOfVehicleMax,
        disableReturnToDepot: res.body.disableReturnToDepot
      }
      localStorage.setItem('fleetConfigSetup', JSON.stringify(fleetConfig));
    })
  }
  /************************************* Load Current Laguage (Like ENglish)**********************/
  useLanguage (lang: any) {
    this.spinner.show()
    console.log(lang)

    localStorage.setItem('lang',lang)
    var CurrentLanguage=  this.getCurrentLanguage();
    console.log(CurrentLanguage,"CurrentLanguage")
    this.getTranslation(CurrentLanguage)
    this.chooseLangEl?.nativeElement.classList.remove('show');
    // this.collapseLanguageAccordion(); // Collapse the language accordion
    // this.closeMainDropdown(); // Close the main dropdown
    /* Change flag based on language selection */
    switch (lang) {
      case 'en':
        this.flag = 'us';
        this.currentLang = 'English';
        break;
      case 'my':
        this.flag = 'my';
        this.currentLang = 'Malay';
        break;
      case 'zh':
        this.flag = 'cn';
        this.currentLang = 'Chinese';
        break;
      case 'de':
        this.flag = 'de';
        this.currentLang = 'Deutsch';
        break;
      case 'es':
        this.flag = 'es';
        this.currentLang = 'Español';
        break;
      default:
        this.flag = 'us';
        this.currentLang = 'English';
    }
  }
  
  
  getCurrentLanguage(){
     
    var langCode=localStorage.getItem('lang');
    if(langCode=='my'){
      this.currentLang = 'Malay';
      return {full: 'Malay', code: "my"};
    }else  if(langCode=='zh'){
      this.currentLang = 'Chinese';
      return {full: 'Chinese', code: "zh"};
    }
    else  if(langCode=='zh'){
      this.currentLang = 'Chinese';
      return {full: 'Chinese', code: "zh"};
    }
    else  if(langCode=='de'){
      this.currentLang = 'Deutsch';
      return {full: 'Deutsch', code: "de"};
    }
    else  if(langCode=='es'){
      this.currentLang = 'Español';
      return {full: 'Español', code: "es"};
    }else {
      this.currentLang = 'English';
      return {full: 'English', code: "en"};
    }
  }
  /************************** Get Language Translated Dynamically (Preeti) ************************************/
  getTranslation(CurrentLanguage: any) {
    this.spinner.show();
   this.push(this.languageTranslateService.getTranslateByLanguage(CurrentLanguage.code).subscribe((res: any) => {
       this.words = res.body;
      this.languageTranslateService.updateWords(this.words);
      this.languageTranslateService.updateMenu(this.words);
      this.spinner.hide();
    }));
   
  }

  /************************************* Get Tanant Addon Feature List **********************/
  loadTenantInfo(){
    this.settingsService.getTenantList().subscribe((res: any) => {
     // console.log(res.body.length,' SET', res);
      
      var addonFeatureList: any[] =[]
        
      if(res.body.length >= 1){
        res.body.forEach((element: any) => {
          addonFeatureList.push(element.addonFeature)
        });
        
        localStorage.setItem('addonFeatureList', JSON.stringify(addonFeatureList));
        this.headerTitleService.refreshSideBar();
        }else{
          localStorage.setItem('addonFeatureList', JSON.stringify(addonFeatureList));
          this.headerTitleService.refreshSideBar();
        }
        if(localStorage.getItem('addonFeature') == "TTL"){
          this.getTocken()
        }
      

  })

}
/************************************* Get TTL Sales Force Access Toecken **********************/
getTocken(){
  this.iqcfqcService.getSalesForceAccessToken()
  .subscribe((data: any) => {
   localStorage.setItem('accessToken',data.error.text);
    
  })
} 

  /************************************* Get User Unread Message **********************/
  getUnreadMessages() {
    this.messagesService.getUnreadMessages(this.auth.getUserId()).subscribe((res:any) => {
      // //console.log("Unread Messages ", res);
      var threads = _.groupBy(res, 'messageThreadId');
      this.messageCount = Object.keys(threads).length;
    });
  }
/************************************* Initialize WebSocket Connection **********************/
  initializeWebSocketConnection(){
    var serverUrl = environment.webSocket_url+this.auth.getToken();
    let ws = new SockJS(serverUrl);
    this.stompClient = Stomp.over(ws);
    // this.stompClient.debug = null; //Debug logging for sockets
    this.stompClient.connect({}, (frame :any) => {
      // //console.log("Web Socket Status ", frame);
      if(frame.command == "CONNECTED"){
        this.stompClient.subscribe("/user/queue/notify-message", (message : any) => {
          this.webSocket.emitMessage(message);
        });
        this.stompClient.subscribe("/user/queue/notify", (notification: any) => {
          this.webSocket.emitNotification(notification);
        });
        this.stompClient.subscribe("/user/queue/notify-shift", (notification: any) => {
          this.webSocket.emitShiftsCancelNotification(notification);//for shifts component
        });
        this.stompClient.subscribe("/user/queue/notify-online-status", (status: any) => {
          this.webSocket.emitOnlineStatus(status);
        });
        this.stompClient.subscribe("/user/queue/sales-force-status", (message: any) => {
          this.webSocket.emitSalesforceMessage(JSON.parse(message.body));
        });
        this.stompClient.subscribe("/user/queue/calender-status", (message: any) => {
          this.webSocket.emitGoogleCalenderMessage(JSON.parse(message.body));
        });
        this.stompClient.subscribe("/user/queue/notify/fleet", (notification: any) => {
          this.webSocket.emitActivityNotification(notification);
        });
      }
    }, (message:any) => {
      // check message for disconnect
      // //console.log("Socket Disconnect ", message);
      this.initializeWebSocketConnection();
  });
  }


/************************************* Change User Status Online/ Offline **********************/
  changeOnlineStatus(val: boolean) {
    if(!val){
      this.userService.getUserLocationActive().subscribe((res1 : any) => {
        if(res1.newRecordId){
          this.lockVariable = true;
         // this.toaster.warn('', 'Unable to change to offline while journey is in progress');
           return;
        }else{
          this.lockVariable = val;
          if(this.lockVariable){
            this.onlineStatus = true;
          }
          else{
            this.onlineStatus = false;
          }
          this.userService.updateUserOnlineStatus(this.auth.getUserId(), this.onlineStatus).subscribe((res : any) => {
          });
        }
      });
    }
    else{
      this.lockVariable = val;
      if(this.lockVariable){
        this.onlineStatus = true;
      }
      else{
        this.onlineStatus = false;
      }
      this.userService.updateUserOnlineStatus(this.auth.getUserId(), this.onlineStatus).subscribe((res : any) => {
      });
    }
  }

  generatePushNotification(notification : any, type: any) {
    let options = new PushNotificationOptions();
    options.icon = '../../assets/images/Fieldex_B_W.png';
    options.sound = '../../assets/sounds/message.mp3';
    var myAudio = new Audio("../../assets/sounds/message.mp3");
       
    if(type == 'notification'){
      var title : any = this.formatNotificationTitle(notification.type);
      options.body = notification.title;
      notification.notificationType = 'notification';
      options.data = notification;
    } else if(type == 'message'){
      var title : any = `New Message from ${notification.from.fullName}`;
      options.body = notification.message;
      notification.notificationType = 'message';
      options.data = notification;
    } 
    this.pushNotificationService.create(title, options).subscribe((notif) => {
      if (notif.event.type === 'show') {
        //console.log('onshow');
        myAudio.play();
        setTimeout(() => {
          notif.notification.close();
        }, 10000);
      }
      if (notif.event.type === 'click') {
        if(notif.notification.data.notificationType == 'notification'){
          this.viewNotification(notif.notification.data);
        } else if(notif.notification.data.notificationType == 'message'){
          this.router.navigate(["/dashboard/messages/employees"]);
        }
        //console.log('click');
        notif.notification.close();
      }
      if (notif.event.type === 'close') {
        //console.log('close');
      }
    },
    (err) => {
         //console.log(err);
    });
  }
  formatNotificationTitle(str : any) {
    var frags = str.split('_');
    for (var i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase();
    }
    return frags.join(' ');
  }
/************************************* get Notification and Mark As read **********************/
  clearAllNotifications(){
    this.notificationService.clearUnreadNotifications(this.auth.getUserId()).subscribe((res: any) => {
      //console.log("Notification All status ", res);
    })
  }

  openNotificationPanel(){
    
    console.log('openNotificationPanel',this.oldCount)
    
    if(!this.showNotificationPanel){this.getNotifications(this.auth.getUserId())
    this.showNotificationPanel=true
    this.oldCount = this.notificationCount;
    }
  }

  getNotifications(id : any) {
   
    forkJoin(this.notificationService.getReadNotifications(id),
    this.notificationService.getUnreadNotifications(this.auth.getUserId())
    ).subscribe(([readNotificationResponse,unreadNotificationResponse]) => {
        var allNotifications = [];
        for(var i=0;i<=readNotificationResponse.length-1;i++){
         // if(readNotificationResponse[i].status === "READ"){
            allNotifications.push(readNotificationResponse[i]);
          //}
        }
        if(unreadNotificationResponse.length > 0){
          for(var i=0;i<=unreadNotificationResponse.length-1;i++){
            allNotifications.push(unreadNotificationResponse[i]);
          }
        }
        this.notifications = allNotifications;
        this.displayDefaultIcon()
       // console.log("allNotifications    ", this.notifications);
        var dateformat : any =localStorage.getItem('date_format')
        AppComponent.calendarFormat.lastWeek = dateformat.toUpperCase();
        this.notifications.forEach((element) => {
          element.calendarTime = moment(element.createdDate).calendar(null, AppComponent.calendarFormat);
        })
    })
  }

  displayDefaultIcon(){
    this.notifications.forEach(notification => {
      if( notification.type.indexOf('TASK_') > -1 || notification.type.indexOf('LEAVE_') > -1 || notification.type.indexOf('CONSIGNMENT_') > -1
       || notification.type.indexOf('REQUISITION_') > -1 || notification.type.indexOf('MAGNUM_BENCH_MARK_') > -1 || notification.type.indexOf('BIN_TRANSFER_REQUEST') > -1){
        
        notification.displayDefaultIcons= false
        notification.icon = 'assets/svg/notification/default.svg'
     }else if(notification.type.indexOf('TEAM_') > -1){
      notification.icon = 'assets\svg\notification\team.svg'

      // }else if(notification.type.indexOf('TASK_') > -1){
      // notification.icon = 'assets\svg\header\task-white.svg'
  
      }else if(notification.type.indexOf('LEAVE_') > -1){
      notification.icon = 'assets\svg\notification\leave.svg'

      }else if(notification.type.indexOf('CONSIGNMENT_') > -1 || notification.type.indexOf('REQUISITION_') > -1 || notification.type.indexOf('BIN_TRANSFER_REQUEST') > -1){
      notification.icon = 'assets\svg\notification\inventory.svg'
        notification.displayDefaultIcons= true
     }  
    });
   
  }
  viewNotification(notification: any) {
    //console.log(notification, notification.type.indexOf('LEAVE_APPLIED'));
    this.notificationService.setNotificationStatus(notification.id).subscribe((res: any) => {
        // Temporarily disable route reuse for this navigation
        const originalReuseStrategy = this.router.routeReuseStrategy.shouldReuseRoute;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';

        let navigationPromise: Promise<boolean>;

        if(notification.type.indexOf('TEAM_') > -1){
            navigationPromise = this.router.navigate([`/dashboard/setup/teams/view/${notification.parentId}`], { queryParams: { id: notification.parentId, backLocation: 'allTeams' } });
        } else if(notification.type.indexOf('TASK_') > -1 && notification.type !== 'TASK_DELETED'){
            this.openTaskViewModal(notification.parentId);
            navigationPromise = Promise.resolve(true); // no navigation needed
        } else if(notification.type.indexOf('LEAVE_') > -1){
            navigationPromise = this.router.navigate([`/dashboard/leave/view/${notification.parentId}`], { queryParams: { id: notification.parentId } });
        } else if(notification.type.indexOf('REPORT_FILE') > -1){
            navigationPromise = this.router.navigate([`/dashboard/reports/main`], { queryParams: { id: notification.parentId, mode: 'view' } });
        } else if(notification.type.indexOf('REPORT_FOLDER') > -1){
            navigationPromise = this.router.navigate([`/dashboard/reports/all`]);
        } else if(notification.type.indexOf('JOB_ORDER_') > -1 ){
            navigationPromise = this.router.navigate([`/dashboard/jobOrders/view/${notification.parentId}`], { queryParams: { jobOrderId: notification.parentId } });
        } else if(notification.type.indexOf('SURVEY_RESPONDED') > -1){
            navigationPromise = this.router.navigate([`/dashboard/jobOrders/view/${notification.parentId}`], { queryParams: { jobOrderId: notification.parentId } });
        } else if(notification.type.indexOf('CANCELLATION_REQUEST_') > -1){
            console.log('HERE');
            this.util.setTabActive("Cancellations");
            navigationPromise = this.router.navigate(["/dashboard/shifts/all"]);
        } else if(notification.type.indexOf('SHIFT') > -1){
            this.util.setTabActive("My Shifts");
            navigationPromise = this.router.navigate(["/dashboard/shifts/all"]);
        } else if(notification.type.indexOf('DELIVERY_ORDER_CREATED') > -1 && this.auth.addonFeature() === 'SNL_TENANT'){
            navigationPromise = this.router.navigate([`/dashboard/deliveryOrders/view/${notification.parentId}`], { queryParams: { doId: notification.parentId } });
        } else if(notification.type.indexOf('CONSIGNMENT_') > -1){
            navigationPromise = this.router.navigate([`/dashboard/inventory/consignment/view/${notification.parentId}`], { queryParams: { id: notification.parentId } });
        } else if(notification.type.indexOf('REQUISITION_') > -1){
            navigationPromise = this.router.navigate([`/dashboard/inventory/requisition/view/${notification.parentId}`], { queryParams: { id: notification.parentId } });
        } else if(notification.type.indexOf('MAGNUM_BENCH_MARK_') > -1){
            navigationPromise = this.router.navigate([`/dashboard/jobOrders/view/${notification.parentId}`], { queryParams: { jobOrderId: notification.parentId } });
        } else if(notification.type.indexOf('UPCOMMING_PREVENTIVE_MAINTENANCE_') > -1){
            navigationPromise = this.router.navigate(['/dashboard/assets/preventative-maintenance/list']);
        } else if(notification.type.indexOf('BIN_TRANSFER_REQUEST') > -1){
            navigationPromise = this.router.navigate([`/dashboard/inventory/binTransfer/view/${notification.parentId}`], { queryParams: { id: notification.parentId } });
        } else if(notification.type.indexOf('LOCATION_BIN_') > -1){
            navigationPromise = this.router.navigate([`/dashboard/inventory/bin/view/${notification.parentId}`], { queryParams: { id: notification.parentId, view: 'location' } });
        } else if(notification.type.indexOf('USER_BIN_') > -1){
            navigationPromise = this.router.navigate([`/dashboard/inventory/bin/view/${notification.parentId}`], { queryParams: { id: notification.parentId, view: 'user' } });
        } else if(notification.type.indexOf('USER_WORKING_HOUR_UPDATED') > -1){
            navigationPromise = this.router.navigate(["/dashboard/setup/allUser/view"], { queryParams: { id: notification.parentId, edit: false } });
        } else if(notification.type.indexOf('MAINTENANCE_COMPLETED') > -1){
            navigationPromise = this.router.navigate([`/dashboard/assets/preventative-maintenance/view/${notification.parentId}`], { queryParams: { id: notification.parentId } });
        } else {
            navigationPromise = Promise.resolve(false); // default case, no navigation
        }

        navigationPromise.then(() => {
            // Reset the route reuse strategy and onSameUrlNavigation to the default after navigation
            this.router.routeReuseStrategy.shouldReuseRoute = originalReuseStrategy;
            this.router.onSameUrlNavigation = 'ignore';
        });
    });
}


  openTaskViewModal(id: any){
    this.visibleSidebarTask = true
    this.newTaskId = id
  }
  closeSlide(){
    this.visibleSidebarTask=false
    this.newTaskId = null
  }

  public executeAddon() {
    const a = document.createElement('a');
    a.href = 'https://www.custella.com/contact-us';
    a.target = "_blank";

    a.click();
  }
  handleClick(event: MouseEvent) {
    //console.log('clicked');
    this.closeSlide()
}
  resetUserTab(){
    localStorage.removeItem("activeIndexAccess")
   
  }
  // Close the entire dropdown
  // closeMainDropdown() {
  //   const dropdownElement = document.getElementById('dropdownMenu');
  //   const bsDropdown = bootstrap.Dropdown.getInstance(dropdownElement); // Get the Bootstrap dropdown instance
  //   if (bsDropdown) {
  //     bsDropdown.hide(); // Close the dropdown
  //   }
  // }

  // // Collapse the language accordion
  // collapseLanguageAccordion() {
  //   const accordionElement = document.getElementById('choose-lang');
  //   const bsCollapse = bootstrap.Collapse.getInstance(accordionElement) || new bootstrap.Collapse(accordionElement, { toggle: false });
  //   if (bsCollapse) {
  //     bsCollapse.hide(); // Collapse the accordion
  //   }
  // }
}
