<!-- TabMenu -->
<div  class="position-relative d-flex flex-column-100">
    <div [hidden]="noSalesOrders" class="ag-grid-table-full h-100-10" [ngClass]="{ ' ag-grid-table': filterToggle }">
        <ag-grid-angular
            #agGrid
            class="ag-theme-balham h-100"
            [suppressDragLeaveHidesColumns]="true"
            [maxConcurrentDatasourceRequests]="-1"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            [defaultColDef]="defaultColDef"
            [frameworkComponents]="frameworkComponents"
            (cellClicked)="viewSalesOrder($event)"
            (gridReady)="onGridReady($event)"
            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            [getContextMenuItems]="getContextMenuItems"
        ></ag-grid-angular>

        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end" id="label_SalesOrderTableRec">
                {{ totalRecord }}&nbsp;{{translate?.general.messages.records}}
            </p>
        </div>
    </div>

    <div *ngIf="noSalesOrders" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound"/>
            <p class="text-6 my-1 font-weight-bold" >
                {{translate?.general.messages.noRecordsFound}}
            </p>
        </div>
    </div>

    <!-- Filter -->
    <div class="custella-form-container-content-pane layout-filter" [ngClass]="{ 'layout-filter-active': filterToggle }" *ngIf="filterToggle">
        <div class="custella-form-container-content-pane-title">
            <span class="text-14 font-medium" id="label_SalesOrderFilter">{{translate?.general.messages.filterBy}}</span>
            <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="translate?.general.buttons.closeFilter.singular" id="btn_SalesOrderFilter" (click)="toggleFilterView()"></i>
        </div>
        <div class="custella-content">
            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_SalesOrderDate">{{translate?.salesOrder.fields.orderDate.singular}}</span>
                <span class="p-input-icon-right w-100">
                    <i class="pi pi-calendar" style="z-index: 1;"></i>
                    <p-calendar
                        #myCalendar
                        type="text"
                        class="w-100"
                        dateFormat="dd/mm/yy"
                        name="dueDate"
                        [readonlyInput]="true"
                        [(ngModel)]="dateRange"
                        [placeholder]="translate?.general.messages.none"
                        selectionMode="range"
                        [(ngModel)]="dateRange"
                        (onSelect)="onDateChange($event)"
                        [yearNavigator]="true"
                        yearRange="2000:2030"
                        class="custella-calendar"
                        [showButtonBar]="false"
                        id="picklist_SalesOrderDateField"
                    appendTo="body"></p-calendar>
                </span>
            </div>
            <div class="custella-content-input col-12">
                <span class="custella-content-input-title" id="label_SalesOrderStatus">{{translate?.salesOrder.fields.status.singular}}</span>
                <span class="p-input-icon-right w-100">
                    <p-dropdown
                        class="pe-2"
                        name="status"
                        [options]="statuses"
                        optionLabel="fieldLabel"
                        optionValue="name"
                        filterBy="fieldLabel"
                        [placeholder]="translate?.general.messages.none"
                        (onChange)="filterResults()"
                        [(ngModel)]="filter.status"
                        [filter]="true"
                        [showClear]="filter.status ? true : false"
                        id="picklist_SalesOrderStatusField"
                    ></p-dropdown>
                </span>
            </div>
            <!-- <div class="custella-content-input col-12" [hidden]="data && data.customerId">
                <span class="custella-content-input-title">{{ "customer"  }}</span>
                <p-dropdown
                    class="pe-2"
                    name="customers"
                    [options]="customers"
                    optionLabel="name"
                    optionValue="id"
                    filterBy="name"
                    placeholder="{{ 'filter_by_customer'  }}"
                    (onChange)="filterResults()"
                    [filter]="true"
                    [(ngModel)]="filter.customerId"
                    [showClear]="filter.customerId ? true : false"
                ></p-dropdown>
            </div> -->

            <div class="custella-content-input col-12" [hidden]="data && data.customerId">
                <span class="custella-content-input-title" id="label_SalesOrderCustomer" >{{translate?.customer.label.singular}}</span>
                <div class="p-inputgroup search">
                    <span class="p-input-icon-right w-100">
                        <p-autoComplete
                            (onSelect)="onCustomerSelect($event)"
                            field="name"
                            [suggestions]="customers"
                            (completeMethod)="getCustomer($event)"
                            [(ngModel)]="selectedCustomer"
                            [placeholder]="translate?.general.messages.none"
                            id="picklist_SalesOrderCustomerField"
                            styleClass="w-100"
                        >
                        </p-autoComplete>
                        <i *ngIf="selectedCustomer && selectedCustomer?.id" (click)="clearValue('customer')" class="p-autocomplete-clear-icon pi pi-times" id="btn_SalesOrderClose"></i>
                    </span>
                    <button type="button" pButton icon="fa-regular fa-magnifying-glass" [pTooltip]="translate?.customer.tooltips.customerLookup.singular" tooltipPosition="left" (click)="showTable('customer')" id="btn_SalesOrderSearch"></button>
                </div>
            </div>

            <div>
                <span href="#" class="clear" (click)="clearAll()"  id="subtitle_SalesOrderClear">{{ translate?.general.buttons.clearAll.singular }}</span>
            </div>
        </div>
    </div>
</div>

<!-- FOR CENTER IN PAGE -->
<footer *ngIf="showingTable.customer" class="custella-form-container-footer">
    <p-dialog [header]="translate?.customer.sections.sec5.singular" [(visible)]="showingTable.customer" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
        <app-custella-customers (selectCustomerEvent)="onCustomerSelect($event)" (closeCustomerEvent)="closeTable('customer')"></app-custella-customers>
    </p-dialog>
</footer>